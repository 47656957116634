/* Container styles */
.container {
    font-family: Arial, sans-serif;
    color: #333;
}

/* Header styles */
.header {
    display: flex;
    justify-content: space-between;
    padding-top:  50px; 
    font-family: 'Roboto mono', monospace;
    background-color: transparent;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.logo {
    margin-left: 50px;
    margin-top: -90px; 
    height: 200px; /* Sets the logo height to 150px */
}
.logobody{
    padding-top: 50px;
    height: 500px;
}
.logofooter{
    margin-left: -230px; 
    padding-left: 20px; 
    height: 220px;
}

.nav {
    display: flex;
    gap: 40px;
    padding-right: 50px;
    font-size: large;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 100px;
    z-index: 1;
    padding: 10px;
}

.nav a {
    color: #333;
    text-decoration: none;
}

/* Main body styles */
.main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e6f7ff;
    color: #007bb5;
    font-size: 2rem;
    text-align: center;
}

/* Footer styles */
.footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #d9f8ff;
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 150px; /* Set the footer height */
}

.logoSection {
   display: flex;
}

.linkSection {
    font-family: 'Roboto mono',monospace;
    display: flex;
    gap: 50px;
}

.linkSection h4 {
    margin: 0 0 10px;
}

.linkSection a {
    display: block;
    color: #333;
    text-decoration: none;
    margin: 5px 0;
}

.social-media {
    display: flex; /* Use flexbox to arrange items horizontally */
    gap: 15px; /* Space between each social media icon */
    align-items: center; /* Center icons vertically */
}

.social-media a {
    display: flex; /* Align items inside the link */
    align-items: center; /* Center icons vertically */
    color: #333; /* Ensure text color is consistent */
    text-decoration: none; /* Remove underline */
}
