/* Team Page Container */
.team-page {
    font-family: Arial, sans-serif;
    background-color: #e6f7ff; /* Matching the main body background */
    color: #333;
    padding: 20px; /* Add padding for spacing */
    text-align: center; /* Center text */
}

/* Team Member Grid */
.team-grid {
    display: grid; /* Use CSS Grid for layout */
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive grid */
    gap: 20px; /* Space between team members */
    padding: 20px; /* Add padding to the grid */
}

/* Individual Team Member */
.team-member {
    background-color: #ffffff; /* White background for each member */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 15px; /* Inner padding */
    transition: transform 0.3s; /* Smooth transform on hover */
}

/* Hover effect for team member */
.team-member:hover {
    transform: scale(1.05); /* Scale up on hover */
}

/* Team Member Image */
.member-image {
    border-radius: 50%; /* Circular image */
    width: 100px; /* Fixed width */
    height: 100px; /* Fixed height */
    object-fit: cover; /* Cover the image without distortion */
}

/* Member Title */
.member-title {
    font-weight: bold; /* Bold title */
    margin: 10px 0 5px; /* Margin for spacing */
}

/* Social Icons Styling */
.social-icons {
    display: flex;
    justify-content: center; /* Center social icons */
    gap: 15px; /* Space between icons */
}

.social-icons a {
    color: #007bb5; /* Change to a theme color */
    font-size: 20px; /* Size of icons */
    transition: color 0.3s; /* Transition for hover effect */
}

.social-icons a:hover {
    color: #005f8c; /* Darker color on hover */
}

/* Team Page Heading */
.team-page h1 {
    font-size: 2.5rem; /* Larger heading */
    margin-bottom: 20px; /* Space below heading */
}

/* Team Page Description */
.team-page p {
    font-size: 1.2rem; /* Slightly larger font for description */
    margin-bottom: 30px; /* Space below description */
}
